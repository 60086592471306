// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/footer.tsx");
  import.meta.hot.lastModified = "1717702441901.4016";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import logo from "public/gsp-network-logo.svg";
export default function Footer() {
  return <footer className="bg-[#083d77] rounded-lg shadow m-4">
            <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div className="sm:flex sm:items-center sm:justify-between sm:align-center">
                    <Link to={"https://gsp-network.com/"}>
                        <img src={logo} alt="gsp logo" className="mx-auto w-full max-w-[12rem] md:max-w-[16rem] p-10" />
                        <span className="block text-sm text-gray-100 sm:text-center">© {new Date().getFullYear()} GSP Network GmbH</span>
                    </Link>
                    <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-100 sm:mb-0">
                        <li>
                            <Link to="https://gsp-network.com/about/" className="hover:underline me-4 md:me-6">Über uns</Link>
                        </li>
                        <li>
                            <Link to="https://gsp-network.com/impressum/" className="hover:underline me-4 md:me-6">Impressum</Link>
                        </li>
                        <li>
                            <Link to="https://gsp-network.com/datenschutz/" className="hover:underline me-4 md:me-6">Datenschutz</Link>
                        </li>
                        <li>
                            <Link to="https://gsp-network.com/kontakt/" className="hover:underline">Kontakt</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>;
}
_c = Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;